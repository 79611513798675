<template>
  <div v-if="message" class="alert alert-danger" role="alert">
    {{ message }}
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AlertBlock",
  props: {
    message: {
      type: [String, Boolean],
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.alert {
  color: #ff444f;
  font-size: 12px;
  position: absolute;
  bottom: -10px;
  left: 15px;
}
</style>
